import FingerprintJS from '@fingerprintjs/fingerprintjs'
const fpPromise = FingerprintJS.load()

// 加载指纹
const getFp = async function() {
  const fp = await fpPromise
  const result = await fp.get()
  return result.visitorId
}

export async function queryServer(url, callback, fp) {
  let temp_fp = fp
  if (temp_fp === '') {
    temp_fp = await getFp()
  }
  fetch(url, {
    method: 'GET',
    headers: {
      IDENTIFIER: temp_fp,
    },
  })
    .then(res => {
      return res.json()
    })
    .then(json => {
      if (json.hasOwnProperty('detail')) {
        if (json.detail.indexOf('身份认证信息未提供') >= 0) {
          callback({
            data: {
              message: '指纹无效，请联系管理员添加指纹',
              fp: temp_fp,
            },
          })
        }
      } else {
        if (json.errcode !== 200) {
          if (json.errcode === 401) {
            callback({
              data: {
                message: '该域下无此大屏模式',
                fp: temp_fp,
              },
            })
          } else {
            callback({
              data: {
                message: '获取数据失败，请联系管理员',
                fp: temp_fp,
              },
            })
          }
        } else {
          callback(json)
        }
      }
    })
    .catch(reason => {
      callback({
        data: {
          message: '网络异常',
          fp: '',
        },
      })
    })
}
