import React from 'react'

import { DigitalFlop, Decoration10, Decoration3 } from '@jiaminghi/data-view-react'

import './TopReagent.less'
import Pie from './Pie'
import medicineSVG from '../svg/medicine.svg'
import analyticsSVG from '../svg/analytics.svg'
import { ReagentList } from './ReagentList'

export default props => {
  return (
    <div id="digital-flop">
      <div className="flop-side-container">
        <div className="flop-left-title">
          <img src={medicineSVG} className="svg-icon" alt="logo" />
          临期试剂
        </div>
        <div className="flop-container">
          <ReagentList data={props.data} />
        </div>
      </div>
      <div className="digital-flop-item decoration-title-container">
        <Decoration3 className="decoration-title" style={{ width: '350px', height: '50px' }} />
      </div>
      <div className="flop-side-container-pie">
        <div className="flop-left-title-pie">
          <img src={analyticsSVG} className="svg-icon" alt="logo" />
          试剂成本占比
        </div>
        <div className="flop-container">
          <Pie data={props.data} />
        </div>
      </div>
      <Decoration10 />
    </div>
  )
}
