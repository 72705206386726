import React, { useEffect, useState } from 'react'

import {
  BorderBox12,
  FullScreenContainer,
  Loading,
  WaterLevelPond,
} from '@jiaminghi/data-view-react'
import { DailyTable } from './scrollBoard/DailyTable'
import TopHeader from './topHeader'
import InfoBox from './infoBox'
import CustomBorder from './CustomBorder'
import CustomFillBorder from './CustomFillBorder'

export default props => {
  // 计算数量
  const calc_quantity = (check, quantity) => {
    if (props.data && props.data.current_receving_list) {
      return props.data.current_receving_list.reduce(
        function(prev, curr) {
          if (check(curr)) {
            return {
              barcode: prev.barcode + (quantity ? quantity(curr) : curr.barcode_quantity),
              receipt: prev.receipt + 1,
            }
          } else {
            return {
              barcode: prev.barcode + 0,
              receipt: prev.receipt + 0,
            }
          }
        },
        {
          barcode: 0,
          receipt: 0,
        }
      )
    }
    return {
      barcode: 0,
      receipt: 0,
    }
  }

  // 计算今日完成情况
  let today_complete_receipt = 0
  if (props.data.current_receving_list) {
    today_complete_receipt = props.data.current_receving_list.filter(item => {
      return item.inbound_quantity >= item.barcode_quantity && item.barcode_paste_status === 11
    }).length
  }
  let today_complete_barcode = 0
  if (props.data.current_receving_list) {
    props.data.current_receving_list.forEach(item => {
      today_complete_barcode += item.inbound_quantity
    })
  }

  let inbound_percentData = [
    props.data?.inbound_progress?.completed_barcodes,
    props.data?.inbound_progress?.total_barcodes,
  ]
  let delivery_percentData = [
    props.data?.delivery_progress?.delivered_barcodes,
    props.data?.delivery_progress?.total_barcodes,
  ]
  if (isNaN(inbound_percentData[0] / inbound_percentData[1])) {
    inbound_percentData = [0, 100]
  }
  if (isNaN(delivery_percentData[0] / delivery_percentData[1])) {
    delivery_percentData = [0, 100]
  }
  return (
    <div id="data-view">
      <FullScreenContainer>
        <>
          <div
            style={{
              height: '10vh',
              minHeight: '100px',
            }}
          >
            <TopHeader weather={props.data?.weather_info} />
          </div>
          <div>
            <div
              style={{
                marginTop: '10px',
                minHeight: '250px',
                height: '40vh',
                width: 'calc(100% - 50px)',
                marginLeft: '25px',
                marginRight: '25px',
                position: 'relative',
              }}
            >
              <CustomBorder title={'收货待办'}>
                <div className="top-content-container">
                  <InfoBox
                    title={'待打印'}
                    charge={props.data?.responsible_person?.printing}
                    {...{
                      barcode: props.data?.inbound_progress?.printing_barcodes,
                      receipt: props.data?.inbound_progress?.printing_receipts,
                    }}
                  />
                  <InfoBox
                    title={'待贴码'}
                    charge={props.data?.responsible_person?.pasting}
                    {...{
                      barcode: props.data?.inbound_progress?.pasting_barcodes,
                      receipt: props.data?.inbound_progress?.pasting_receipts,
                    }}
                  />
                  <InfoBox
                    title={'待上架'}
                    charge={props.data?.responsible_person?.putting}
                    {...{
                      barcode: props.data?.inbound_progress?.putting_barcodes,
                      receipt: props.data?.inbound_progress?.putting_receipts,
                    }}
                  />

                  <div
                    className={'top-box-container'}
                    style={{
                      flexDirection: 'column',
                      marginTop: '42px',
                      width: '310px',
                    }}
                  >
                    <div
                      className={'top-box-container-box'}
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                      }}
                    >
                      <WaterLevelPond
                        config={{
                          colors: ['#08AED4', '#37ccd0'],
                          data: [
                            (inbound_percentData[0] / inbound_percentData[1]).toFixed(2) * 100,
                            ((inbound_percentData[0] / inbound_percentData[1]) * 0.7).toFixed(2) *
                              100,
                          ],
                          formatter: `${(
                            (inbound_percentData[0] / inbound_percentData[1]) *
                            100
                          ).toFixed(0)}%`,
                          shape: 'roundRect',
                          waveHeight: 30,
                        }}
                        style={{ width: '175px', height: '280px' }}
                      />
                    </div>
                    <div
                      className={'svg-icon-charger'}
                      style={{
                        marginTop: '6px',
                      }}
                    >
                      整体收货进度
                    </div>
                  </div>
                </div>
              </CustomBorder>
            </div>

            <div className={'today-bottom-alert'}>
              今日已完成 {props.data?.inbound_progress?.completed_receipts} 张入库通知单，已入库{' '}
              {props.data?.inbound_progress?.completed_barcodes} 个条码！
            </div>
          </div>
          <div>
            <div
              style={{
                marginTop: '10px',
                minHeight: '250px',
                height: '40vh',
                width: 'calc(100% - 50px)',
                marginLeft: '25px',
                marginRight: '25px',
                position: 'relative',
              }}
              className={'theme-different'}
            >
              <CustomBorder theme={'red'} title={'发货待办'}>
                <div className="top-content-container">
                  <InfoBox
                    title={'待拣货'}
                    charge={props.data?.responsible_person?.picking}
                    {...{
                      barcode: props.data?.delivery_progress?.picking_barcodes,
                      receipt: props.data?.delivery_progress?.picking_receipts,
                    }}
                  />
                  <InfoBox
                    title={'待装箱'}
                    charge={props.data?.responsible_person?.delivering}
                    {...{
                      barcode: props.data?.delivery_progress?.delivering_barcodes,
                      receipt: props.data?.delivery_progress?.delivering_receipts,
                    }}
                  />
                  <InfoBox
                    title={'待配送'}
                    charge={props.data?.responsible_person?.loading}
                    {...{
                      barcode: props.data?.delivery_progress?.loading_boxes,
                      organization: props.data?.delivery_progress?.loading_organizations,
                      receipt: props.data?.delivery_progress?.loading_receipts,
                    }}
                  />

                  <div
                    className={'top-box-container'}
                    style={{
                      flexDirection: 'column',
                      marginTop: '42px',
                      width: '310px',
                    }}
                  >
                    <div
                      className={'top-box-container-box'}
                      style={{
                        backgroundColor: 'transparent',
                        border: 'none',
                      }}
                    >
                      <WaterLevelPond
                        config={{
                          colors: ['#255fc9', '#537bc4'],
                          data: [
                            (delivery_percentData[0] / delivery_percentData[1]).toFixed(2) * 100,
                            ((delivery_percentData[0] / delivery_percentData[1]) * 0.7).toFixed(2) *
                              100,
                          ],
                          formatter: `${(
                            (delivery_percentData[0] / delivery_percentData[1]) *
                            100
                          ).toFixed(0)}%`,
                          shape: 'roundRect',
                          waveHeight: 30,
                        }}
                        style={{ width: '175px', height: '280px' }}
                      />
                    </div>
                    <div
                      className={'svg-icon-charger'}
                      style={{
                        marginTop: '6px',
                      }}
                    >
                      整体发货进度
                    </div>
                  </div>
                </div>
              </CustomBorder>
            </div>

            <div className={'today-bottom-alert-star today-bottom-alert'}>
              <div>*箱数：装箱完成后的箱数</div>
              <div>
                今日已完成 {props.data?.delivery_progress?.delivered_receipts} 张发货通知单，已发货{' '}
                {props.data?.delivery_progress?.delivered_barcodes} 个条码！
              </div>
            </div>
          </div>
        </>
      </FullScreenContainer>
    </div>
  )
}
