import React, { useEffect, useState } from 'react'
export default props => {
  let base = props.height * 0.7
  let padding = base * 0.03
  let corner = base * 0.08
  let points = [
    [padding, padding],
    [props.width - padding, padding],
    [props.width - padding, props.height - padding - corner],
    [props.width - padding - corner, props.height - padding],
    [padding + corner, props.height - padding],
    [padding, props.height - padding - corner],
    [padding, padding],
  ]

  let final_rect = points
    .map(function(point) {
      return point.join(',')
    })
    .join(' ')

  // 装饰件
  let size_left = props.width / 2 - base * 0.55
  let size_w = base * 0.16
  let size_h = base * 0.02
  let left_rect = [
    [padding + size_left, props.height - padding - size_h],
    [padding + size_left + size_w, props.height - padding - size_h],
    [padding + size_left + size_w, props.height - padding],
    [padding + size_left, props.height - padding],
  ]
  let size_right = props.width / 2 - base * 0.55
  let right_rect = [
    [props.width - padding - size_right - size_w, props.height - padding - size_h],
    [props.width - padding - size_right, props.height - padding - size_h],
    [props.width - padding - size_right, props.height - padding],
    [props.width - padding - size_right - size_w, props.height - padding],
  ]

  let size_c = size_h

  let mid_size_w = base * 0.35
  let mid_height = size_h * 3
  let mid_rect = [
    [props.width / 2 - mid_size_w / 2 + size_c, props.height - padding - mid_height],
    [props.width / 2 + mid_size_w / 2 - size_c, props.height - padding - mid_height],
    [props.width / 2 + mid_size_w / 2, props.height - padding],
    [props.width / 2 - mid_size_w / 2, props.height - padding],
  ]

  let para_size_w = base * 0.08

  let left_para = [
    [
      props.width / 2 - mid_size_w / 2 + size_c - para_size_w * 2 + size_c,
      props.height - padding - size_h * 3,
    ],
    [
      props.width / 2 - mid_size_w / 2 + size_c - para_size_w + size_c,
      props.height - padding - size_h * 3,
    ],
    [props.width / 2 - mid_size_w / 2 + size_c - para_size_w, props.height - padding],
    [props.width / 2 - mid_size_w / 2 + size_c - para_size_w * 2, props.height - padding],
  ]

  let right_para = [
    [
      props.width / 2 + mid_size_w / 2 - size_c + para_size_w - size_c,
      props.height - padding - size_h * 3,
    ],
    [
      props.width / 2 + mid_size_w / 2 - size_c + para_size_w * 2 - size_c,
      props.height - padding - size_h * 3,
    ],
    [props.width / 2 + mid_size_w / 2 - size_c + para_size_w * 2, props.height - padding],
    [props.width / 2 + mid_size_w / 2 - size_c + para_size_w, props.height - padding],
  ]

  // 标题分割栏
  let top_h = props.height * 0.25
  let left_w = props.height * 0.25
  let split_h = props.height * 0.005
  let split = [
    [padding + left_w, padding + top_h],
    [props.width - padding - left_w, padding + top_h],
    [props.width - padding - left_w, padding + top_h + split_h],
    [padding + left_w, padding + top_h + split_h],
  ]

  return (
    <>
      <div
        style={{
          position: 'relative',
          width: props.width,
          height: props.height,
        }}
      >
        <svg
          style={{
            position: 'absolute',
            width: props.width,
            height: props.height,
          }}
        >
          <defs>
            <linearGradient id="grad1" x1="0%" x2="0%" y1="0%" y2="100%">
              <stop offset="0%" stopColor="rgb(87,174,245)" stopOpacity="0.11" />
              <stop offset="17%" stopColor="rgb(37,142,229)" stopOpacity="0.16" />
              <stop offset="100%" stopColor="rgb(42,151,241)" stopOpacity="0.22" />
            </linearGradient>
            <linearGradient id="grad2" x1="0%" x2="0%" y1="0%" y2="100%">
              <stop offset="0%" stopColor="#7fbcee" stopOpacity="0" />
              <stop offset="100%" stopColor="#7fbcee" stopOpacity="1" />
            </linearGradient>
            <linearGradient id="grad3" x1="0%" x2="100%" y1="0%" y2="0%">
              <stop offset="0%" stopColor="white" stopOpacity="0" />
              <stop offset="50%" stopColor="white" stopOpacity="1" />
              <stop offset="100%" stopColor="white" stopOpacity="0" />
            </linearGradient>
          </defs>
          <polyline
            points={final_rect}
            stroke={'url(#grad2)'}
            fill={'url(#grad1)'}
            strokeWidth="3"
          ></polyline>
          <polyline points={left_rect} fill={'#6DB6FF'} opacity={0.6} strokeWidth="3"></polyline>
          <polyline points={right_rect} fill={'#6DB6FF'} opacity={0.6} strokeWidth="3"></polyline>
          <polyline points={mid_rect} fill={'#6DB6FF'} opacity={0.6} strokeWidth="3"></polyline>
          <polyline points={left_para} fill={'#6DB6FF'} opacity={1} strokeWidth="3"></polyline>
          <polyline points={right_para} fill={'#6DB6FF'} opacity={1} strokeWidth="3"></polyline>
          <polyline
            points={split}
            stroke={'url(#grad3)'}
            fill={'transparent'}
            strokeWidth="1"
          ></polyline>
        </svg>
        <div
          style={{
            position: 'absolute',
            top: props.height * 0.05 + 'px',
            width: props.width,
            textAlign: 'center',
            color: 'white',
            fontSize: 'calc(10px + 0.65vw)',
          }}
          className={'font-source'}
        >
          {props.title}
        </div>
        <div
          style={{
            position: 'absolute',
            top: props.height * 0.25 + 'px',
            maxHeight: props.height * 0.7 + 'px',
            width: props.width,
            textAlign: 'center',
            color: 'white',
            fontSize: 'calc(14px + 0.45vw)',
          }}
        >
          {props.content}
        </div>
      </div>
    </>
  )
}
