import React, { forwardRef } from 'react'
import { Decoration7 } from '@jiaminghi/data-view-react'
import BillSVG from '../../svg/bill.svg'
import BarcodeSVG from '../../svg/barcode.svg'
import PackageSVG from '../../svg/package.svg'

var InfoBox = function(props) {
  return (
    <div className={'top-box-container'}>
      <div className={'top-box-container-box'}>
        <div>
          <Decoration7>
            <span
              style={{
                marginLeft: '20px',
                marginRight: '20px',
              }}
              className={'top-box-container-title'}
            >
              {props.title}
            </span>
          </Decoration7>
        </div>
        <div className={'top-box-container-box-bottom'}>
          <div>
            <div
              style={{
                color: '#FA7B69',
              }}
              className={'svg-title'}
            >
              <img src={BillSVG} className="svg-icon svg-icon-title-icon" alt="logo" />
              <span
                className={'svg-icon-title'}
                style={{
                  marginLeft: '3px',
                }}
              >
                单据数
              </span>
            </div>
            <div
              style={{
                color: '#FA7B69',
              }}
            >
              <span className={'svg-icon-number'}>{props.receipt}</span>
            </div>
            {props.organization ? (
              <div className={'organization-number'}>客户数: {props.organization}</div>
            ) : (
              <></>
            )}
          </div>
          <div>
            <div
              style={{
                color: '#FEC4BC',
              }}
              className={'svg-title'}
            >
              <img
                src={props.title === '待配送' ? PackageSVG : BarcodeSVG}
                className="svg-icon svg-icon-title-icon"
                alt="logo"
              />
              <span
                className={'svg-icon-title'}
                style={{
                  marginLeft: '3px',
                }}
              >
                {props.title === '待配送' ? (
                  <>
                    箱数<div className={'box-star'}>*</div>
                  </>
                ) : (
                  '条码数'
                )}
              </span>
            </div>
            <div
              style={{
                color: '#FEC4BC',
              }}
            >
              <span className={'svg-icon-number'}>{props.barcode}</span>
            </div>
          </div>
        </div>
      </div>
      <div className={'svg-icon-charger'}>负责人: {props.charge}</div>
    </div>
  )
}

export default InfoBox
