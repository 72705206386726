import React, { useEffect, useState } from 'react'

import { Decoration7, ScrollBoard } from '@jiaminghi/data-view-react'

import './ReagentList.less'

export const ReagentList = props => {
  let config = {
    header: ['名称', '批号', '数量'],
    data: props.data.near_expiration.map(item => {
      return [item.product_name, item.batch_name, parseFloat(item.quantity)]
    }),
    index: true,
    indexHeader: '序号',
    columnWidth: [75, 425, 150, 150],
    align: ['center', 'center', 'center', 'center'],
    rowNum: 5,
    headerBGC: '#1981f6',
    oddRowBGC: 'rgba(0, 44, 81, 0.8)',
    evenRowBGC: 'rgba(10, 29, 50, 0.8)',
  }
  return (
    <div className="scroll-board scroll-board-list">
      {props.data.near_expiration.length === 0 && (
        <div className={'complete-svg'}>
          <div>无临期试剂</div>
        </div>
      )}
      {props.data.near_expiration.length !== 0 && <ScrollBoard config={config} />}
    </div>
  )
}
