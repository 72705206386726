import { BorderBox9, Charts, Decoration3, Decoration7 } from '@jiaminghi/data-view-react'
import React from 'react'
import './CostPie.less'
import checkSVG from '../svg/check.svg'
export default props => {
  let data = []
  props.data.validity = {
    half_year: 430,
    month: 308,
    other: 860,
    quarter: 1061,
  }
  let data_sum =
    props.data.validity.quarter +
    props.data.validity.half_year +
    props.data.validity.month +
    props.data.validity.other
  function uni_format_number(number) {
    try {
      let output = (Math.round((parseFloat(number) + Number.EPSILON) * 100) / 100).toLocaleString(
        'en-US'
      )
      if (output && output !== 'NaN') {
        return output
      }
      return ''
    } catch (e) {
      console.log(e)
      return ''
    }
  }
  if (props.data.validity.quarter !== 0) {
    data.push({
      name:
        '三个月内' +
        ' - ' +
        uni_format_number((props.data.validity.quarter / data_sum) * 100) +
        '%',
      value: props.data.validity.quarter,
    })
  }

  if (props.data.validity.half_year !== 0) {
    data.push({
      name:
        '半年内' +
        ' - ' +
        uni_format_number((props.data.validity.half_year / data_sum) * 100) +
        '%',
      value: props.data.validity.half_year,
    })
  }

  if (props.data.validity.month !== 0) {
    data.push({
      name:
        '一个月内' + ' - ' + uni_format_number((props.data.validity.month / data_sum) * 100) + '%',
      value: props.data.validity.month,
    })
  }

  if (props.data.validity.other !== 0) {
    data.push({
      name:
        '半年以上' + ' - ' + uni_format_number((props.data.validity.other / data_sum) * 100) + '%',
      value: props.data.validity.other,
    })
  }

  const option = {
    series: [
      {
        type: 'pie',
        data: data,
        outsideLabel: {
          style: {
            fontSize: 12,
          },
        },
      },
    ],
  }
  return (
    <div className="chart-cost-pie">
      <div className="chart-name">
        {props.hasOwnProperty('svg') && props.svg}
        <div>
          <span style={{ fontSize: '20px' }}>试剂效期情况</span>
          <Decoration3
            style={{
              width: '200px',
              height: '20px',
            }}
          />
        </div>
      </div>
      {data_sum !== 0 && <Charts option={option} />}
      {data_sum === 0 && (
        <div className={'complete-svg'}>
          <img src={checkSVG} className="svg-icon" alt="logo" />
          <div>无临期试剂</div>
        </div>
      )}
    </div>
  )
}
