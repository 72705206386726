import { Charts, Decoration3 } from '@jiaminghi/data-view-react'
import React from 'react'
import checkSVG from '../svg/check.svg'

export default props => {
  let data = []
  let data_sum =
    props.data.validity.quarter +
    props.data.validity.half_year +
    props.data.validity.month +
    props.data.validity.other
  function uni_format_number(number) {
    try {
      let output = (Math.round((parseFloat(number) + Number.EPSILON) * 100) / 100).toLocaleString(
        'en-US'
      )
      if (output && output !== 'NaN') {
        return output
      }
      return ''
    } catch (e) {
      console.log(e)
      return ''
    }
  }

  if (props.data.validity.quarter !== 0) {
    data.push({
      name:
        '三个月内' +
        ' - ' +
        uni_format_number((props.data.validity.quarter / data_sum) * 100) +
        '%',
      value: props.data.validity.quarter,
    })
  }

  if (props.data.validity.half_year !== 0) {
    data.push({
      name:
        '半年内' +
        ' - ' +
        uni_format_number((props.data.validity.half_year / data_sum) * 100) +
        '%',
      value: props.data.validity.half_year,
    })
  }

  if (props.data.validity.month !== 0) {
    data.push({
      name:
        '一个月内' + ' - ' + uni_format_number((props.data.validity.month / data_sum) * 100) + '%',
      value: props.data.validity.month,
    })
  }

  if (props.data.validity.other !== 0) {
    data.push({
      name:
        '半年以上' + ' - ' + uni_format_number((props.data.validity.other / data_sum) * 100) + '%',
      value: props.data.validity.other,
    })
  }

  const option = {
    series: [
      {
        type: 'pie',
        data: data,
      },
    ],
  }
  return (
    <>
      {data_sum !== 0 && (
        <Charts
          className="zhuChang-pie"
          style={{
            width: '400px',
            height: '200px',
          }}
          option={option}
        />
      )}
      {data_sum === 0 && (
        <div
          className={'complete-svg'}
          style={{
            width: '400px',
            height: '200px',
          }}
        >
          <img src={checkSVG} className="svg-icon" alt="logo" />
          <div>无临期试剂</div>
        </div>
      )}
    </>
  )
}
