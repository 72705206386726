import React, { useContext, useEffect, useState } from 'react'
import Title from './title'
import { ScrollBoard } from '@jiaminghi/data-view-react'
import useWindowDimensions from '../hook/window'
import { DataContext } from '../../App'

export default props => {
  const { height, width } = useWindowDimensions()

  let data = useContext(DataContext)
  let final_data = []
  if (data && data.batches_change_list && Array.isArray(data.batches_change_list)) {
    final_data = data.batches_change_list.map(function(item) {
      return [
        item.product__name,
        item.product__brand_name,
        item.product__spec_name,
        item.origin__parent__name,
      ]
    })
  }

  let config = {
    header: ['物资名称', '厂商', '规格', '实验室'],
    data: final_data,
    index: true,
    indexHeader: '序号',
    columnWidth: [width * 0.05, width * 0.2, width * 0.08, width * 0.09, width * 0.09],
    align: ['center', 'center', 'center', 'center'],
    rowNum: 4,
    headerHeight: height * 0.025,
    headerBGC: 'transparent',
    oddRowBGC: 'rgba(0, 44, 81, 0.8)',
    evenRowBGC: 'rgba(10, 29, 50, 0.8)',
  }
  return (
    <div>
      <Title title={'批号更换清单'} image={'/img/10/batch.png'} />
      {final_data.length !== 0 ? (
        <div className={'right-alert'}>
          <img width={'calc(20px + 3vh)'} height={'calc(20px + 3vh)'} src={'/img/10/alert.png'} />
          <span className={'font-ten'} style={{ color: '#FF3F3F' }}>
            预警
          </span>
          ：
          <span className={'font-ten'} style={{ color: '#FFBF00' }}>
            {final_data.length} 个品项即将更换批号，请提前安排定标
          </span>
        </div>
      ) : (
        <></>
      )}
      <div className={'small-line-container'}>
        <div></div>
      </div>
      <div
        style={{
          marginLeft: 0.03 * width,
          height: 0.22 * height,
          width: 0.45 * width,
        }}
        className={'table-container'}
      >
        <ScrollBoard config={config}></ScrollBoard>
      </div>
    </div>
  )
}
