import React, { useContext, useEffect, useState } from 'react'
import Box from './box'
import Title from './title'
import { DataContext } from '../../App'
import useWindowDimensions from '../hook/window'
import Chart from './chart'
export default props => {
  return (
    <>
      <div className={'chart-container bottom-chart-container'}>
        <div className={'line-container'}>
          <div></div>
        </div>
        <Title
          title={`${new Date().getFullYear()}/${new Date().getMonth() + 1} 班组近7日领用额`}
          image={'/img/10/consume.png'}
        />
        <div className={'chart-main'}>
          <Chart />
        </div>
      </div>
    </>
  )
}
