import React from 'react'

import { FullScreenContainer, Loading } from '@jiaminghi/data-view-react'

import TopHeader from '../common/TopHeader'
import DigitalFlop from './DigitalFlop'

import './index.less'
import { SendNotification } from './scrollBoard/SendNotification'
import { StockInNotification } from './scrollBoard/StockInNotification'
import ScanLine from '../line/ScanLine'
import Error from '../common/Error'
import ChargeAndComsume from '../cangku/ChargeAndComsume'

export default props => {
  return (
    <div id="data-view">
      <FullScreenContainer>
        {Object.keys(props.data).length === 0 && <Loading>Loading...</Loading>}
        {Object.keys(props.data).length === 2 && <Error data={props.data} />}

        {Object.keys(props.data).length >= 3 && (
          <>
            <TopHeader title="总仓IVD数据看板" />
            <div className="main-content">
              <DigitalFlop data={props.data} />

              <div className="block-left-right-content">
                {/*<RankingBoard data={props.data} />*/}

                <div className="block-top-bottom-content">
                  <ChargeAndComsume data={props.data} />
                  <ScanLine data={props.data} title={'扫描统计'} />
                  <StockInNotification data={props.data} type={' 待收货 '} />
                  <SendNotification data={props.data} type={' 待发货 '} />
                </div>
              </div>
            </div>
          </>
        )}
      </FullScreenContainer>
    </div>
  )
}
