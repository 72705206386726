import React, { useContext, useEffect, useState } from 'react'
import * as d3 from 'd3'
import useWindowDimensions from '../hook/window'
import { DataContext } from '../../App'
export default props => {
  const { height, width } = useWindowDimensions()

  let color_set = {
    发热组: [
      {
        color: '#FFE196',
        op: '1',
        per: '0%',
      },
      {
        color: '#FFC73C',
        op: '1',
        per: '17%',
      },
      {
        color: '#FDF0A7',
        op: '0',
        per: '100%',
      },
    ],
    免疫组: [
      {
        color: '#83ABFF',
        op: '1',
        per: '0%',
      },
      {
        color: '#4682FF',
        op: '1',
        per: '17%',
      },
      {
        color: '#4682FF',
        op: '0',
        per: '100%',
      },
    ],
    临检组: [
      {
        color: '#E95649',
        op: '1',
        per: '0%',
      },
      {
        color: '#FF7469',
        op: '1',
        per: '17%',
      },
      {
        color: '#FD9890',
        op: '0',
        per: '100%',
      },
    ],
    门诊组: [
      {
        color: '#1CCDA7',
        op: '1',
        per: '0%',
      },
      {
        color: '#2DD6B1',
        op: '1',
        per: '17%',
      },
      {
        color: '#6AE9CE',
        op: '0',
        per: '100%',
      },
    ],
    急诊组: [
      {
        color: '#EC9B6F',
        op: '1',
        per: '0%',
      },
      {
        color: '#DE8E4E',
        op: '1',
        per: '17%',
      },
      {
        color: '#F4C6A1',
        op: '0',
        per: '100%',
      },
    ],
    生殖组: [
      {
        color: '#09CEEE',
        op: '1',
        per: '0%',
      },
      {
        color: '#0B87B3',
        op: '1',
        per: '17%',
      },
      {
        color: '#0B7BA6',
        op: '0',
        per: '100%',
      },
    ],
    生化组: [
      {
        color: '#2FB925',
        op: '1',
        per: '0%',
      },
      {
        color: '#36A964',
        op: '1',
        per: '17%',
      },
      {
        color: '#31B632',
        op: '0',
        per: '100%',
      },
    ],
    分子基因组: [
      {
        color: '#CB47CD',
        op: '1',
        per: '0%',
      },
      {
        color: '#AA56DA',
        op: '1',
        per: '17%',
      },
      {
        color: '#C14DD2',
        op: '0',
        per: '100%',
      },
    ],
    分子细胞组: [
      {
        color: '#115EFF',
        op: '1',
        per: '0%',
      },
      {
        color: '#023FBD',
        op: '1',
        per: '17%',
      },
      {
        color: '#023FBD',
        op: '0',
        per: '100%',
      },
    ],
    微生物组: [
      {
        color: '#1FE4E4',
        op: '1',
        per: '0%',
      },
      {
        color: '#32CDCD',
        op: '1',
        per: '17%',
      },
      {
        color: '#4682FF',
        op: '0',
        per: '100%',
      },
    ],
  }
  let _data = useContext(DataContext)
  let data = []
  if (Array.isArray(_data.consumptions)) {
    data = _data.consumptions
  }
  let dates = [...new Set(data.map(item => item.creation_datetime__date))]

  // 排除周末
  dates = dates.filter(function(item) {
    if (item !== '2023-10-07' && item !== '2023-10-06') {
      if (new Date(item).getDay() === 0 || new Date(item).getDay() === 6) {
        return false
      }
    }
    return true
  })

  if (dates.length > 7) {
    dates = dates.slice(-7)
  }

  let depList = []

  dates = dates.map(function(item) {
    return {
      date: item,
      values: data.reduce((prev, curr) => {
        if (color_set.hasOwnProperty(curr.origin__parent__name)) {
          if (curr.creation_datetime__date === item) {
            prev[curr.origin__parent__name] = curr.amount / 10000
          }
          if (depList.indexOf(curr.origin__parent__name) === -1) {
            depList.push(curr.origin__parent__name)
          }
        }
        return prev
      }, {}),
    }
  })

  dates = dates.filter(function(item) {
    return Object.keys(item.values).length >= 1
  })

  let final = dates

  var d = final.reduce(
    (prev, curr) => {
      return prev.concat(Object.values(curr.values))
    },
    [0]
  )
  var extent = d3.extent(d)

  let axis_line = height * 0.15 - 20
  var linearScale = d3
    .scaleLinear()
    .domain(extent)
    .range([axis_line, 0.025 * height])
    .nice()

  useEffect(() => {
    var axis = d3.axisLeft(linearScale).tickSize(0)
    // y axis
    const ticksAmount = 4
    let maxValue = Math.max(...d)
    let minValue = 0
    const tickStep = (maxValue - minValue) / ticksAmount
    let split = 10
    if (maxValue >= 100) {
      split = 20
    }
    if (maxValue >= 200) {
      split = 50
    }
    if (maxValue >= 1000000) {
      split = 100000
    }
    const step = Math.ceil(tickStep / split) * split
    const yAxis = d3
      .axisLeft(linearScale)
      .ticks(ticksAmount)
      .tickValues(d3.range(minValue, maxValue + step, step))
      .tickFormat(d3.format('d'))

    d3.select('.axis').call(yAxis)

    let gap = width * 0.02
    let remain = 0.9 * width - gap * (final.length - 1)
    let count = final.length

    let unit_width = remain / count

    final.forEach(function(item, index) {
      let base_x = index * gap + index * unit_width
      d3.select('.data-' + index).remove()
      d3.select('.data')
        .append('g')
        .attr('class', 'data-' + index)
        .attr('transform', `translate(${base_x}, 0)`)
      d3.select('.data-' + index)
        .append('text')
        .attr('fill', 'white')
        .attr('x', unit_width / 2)
        .attr('y', axis_line + 20)
        .text(
          item.date.split('-').length >= 3
            ? item.date.split('-')[1] + '-' + item.date.split('-')[2]
            : item.date
        )
        .attr('text-anchor', 'middle')

      // 柱状
      let v_count = Object.keys(item.values).length
      let v_unit_width = unit_width / v_count / 1.1
      let base_start = 0
      if (v_unit_width < unit_width / v_count) {
        base_start = (unit_width - v_unit_width * v_count) / 2
      }
      Object.keys(item.values)
        .sort(function(a, b) {
          return depList.indexOf(a) - depList.indexOf(b)
        })
        .forEach((k, _index) => {
          let corner = height * 0.005
          let y = linearScale(item.values[k]) + corner
          let x = base_start + v_unit_width * _index
          let axis_line_fix = axis_line + 10
          let path = `M${x},${axis_line_fix} V${y} a${corner},${corner} 0 0 1 ${corner},-${corner} a${corner},${corner} 0 0 1 ${corner},${corner} V${axis_line_fix}`
          d3.select('.data-' + index)
            .append('path')
            .attr('data-k', k)
            .attr('d', path)
            .attr('fill', `url(#${k})`)
            .attr('stroke', 'url(#white)')
            .attr('stroke-width', '2')

          d3.select('.data-' + index)
            .append('text')
            .attr('x', x + corner)
            .attr('y', y - height * 0.015)
            .text(item.values[k].toFixed(1))
            .attr('fill', 'white')
            .attr('text-anchor', 'middle')
        })
    })

    // 图例
    let prev_width = 0
    Object.keys(color_set)
      .sort(function(a, b) {
        return depList.indexOf(a) - depList.indexOf(b)
      })
      .forEach(function(item) {
        if (depList.indexOf(item) === -1) {
          return 0
        }
        let key = 'data-color-' + item
        d3.select('.' + key).remove()
        d3.select('.color-container')
          .append('g')
          .attr('class', key)

        // 图例(圆圈)
        d3.select('.' + key)
          .append('circle')
          .attr('fill', color_set[item][1].color)
          .attr('cx', prev_width)
          .attr('cy', height * 0.03)
          .attr('r', 5 + width * 0.002)

        // 文字
        d3.select('.' + key)
          .append('text')
          .attr('fill', '#BCCDEB')
          .attr('x', prev_width + width * 0.007)
          .attr('y', height * 0.03)
          .text(item)
          .attr('text-anchor', 'start')
          .attr('dominant-baseline', 'middle')
        // 计算宽度
        prev_width +=
          document.querySelector('g.' + key).getBoundingClientRect().width + width * 0.01
      })

    // 计算宽度后修改
    d3.select('g.color-container').attr(
      'transform',
      `translate(${width * 0.9 -
        document.querySelector('g.color-container').getBoundingClientRect().width}, 0)`
    )
    d3.select('.unit text').remove()
    d3.select('.unit')
      .append('text')
      .attr('x', 0)
      .attr('y', height * 0.02)
      .text('单位: 万元')
      .attr('fill', 'white')

    d3.selectAll('text')
      .style('font-size', 'calc(8px + 0.35vw)')
      .attr('fill', 'white')
  }, [width, height, linearScale, final, axis_line, color_set, d, depList])

  return (
    <div
      style={{
        color: 'white',
      }}
      className={'chart-main-container'}
    >
      <svg width={width * 0.94} height={height * 0.2}>
        {color_set &&
          Object.keys(color_set).map(item => {
            return (
              <linearGradient id={item} x1="0%" x2="0%" y1="0%" y2="100%">
                {color_set[item] &&
                  color_set[item].map(function(c) {
                    return <stop offset={c.per} stopColor={c.color} stopOpacity={c.op} />
                  })}
              </linearGradient>
            )
          })}
        <linearGradient id={'white'} x1="0%" x2="0%" y1="0%" y2="100%">
          <stop offset="0%" stopColor={'white'} stopOpacity="1" />
          <stop offset="17%" stopColor={'white'} stopOpacity="0.5" />
          <stop offset="100%" stopColor={'white'} stopOpacity="0" />
        </linearGradient>

        <g className="axis" transform={`translate(${width * 0.05}, 0)`}></g>
        <g className="data" transform={`translate(${width * 0.05}, 0)`}></g>
        <g className="unit"></g>
      </svg>
      <svg
        className={'color'}
        width={width * 0.94}
        height={height * 0.06}
        style={{
          position: 'absolute',
          right: '0',
          top: '0',
        }}
      >
        <g className={'color-container'}></g>
      </svg>
    </div>
  )
}
