import React from 'react'
import { Decoration3 } from '@jiaminghi/data-view-react'
import './Alert.less'

export default props => {
  return (
    <div className="alert-deps">
      <div className="alert-name">
        {props.hasOwnProperty('svg') && props.svg}
        <div>
          <span style={{ fontSize: '20px' }}>补货提醒</span>
          <Decoration3
            style={{
              width: '200px',
              height: '20px',
            }}
          />
        </div>
      </div>
      <div className="alert-divs">
        {props.data.replenishment.map(item => {
          return (
            <div
              className={
                'alert-div' +
                (item.status === 'normal' ? '' : ' alert-div-alert') +
                (item.name.length >= 5 ? ' alert-div-small' : '')
              }
            >
              {item.name}
            </div>
          )
        })}
      </div>
    </div>
  )
}
