import React from 'react'

import { Decoration5, Decoration7, Decoration8 } from '@jiaminghi/data-view-react'

import './TopHeader.less'
import CrcLogo from '../cangku/img/crc-logo.png'

export default props => {
  return (
    <div id="top-header">
      <Decoration8 className="header-left-decoration" />
      <Decoration5 className="header-center-decoration" />
      <Decoration8 className="header-right-decoration" reverse={true} />
      <div className="center-title">
        <Decoration7>
          <span>{props.title}</span>
        </Decoration7>
      </div>
    </div>
  )
}
