import React, { useEffect, useState } from 'react'
import CloudySVG from '../svg/weather/cloudy.svg'
import SnowSVG from '../svg/weather/snow.svg'
import OverCastSVG from '../svg/weather/overcast.svg'
import RainSVG from '../svg/weather/rain.svg'
import FogSVG from '../svg/weather/fog.svg'
import SunSVG from '../svg/weather/sun.svg'
import WindSVG from '../svg/weather/wind.svg'
export default props => {
  let [weather, setWeather] = useState(undefined)
  useEffect(() => {
    let targetWeather = props.weather
    if (targetWeather.indexOf('多云') !== -1) {
      setWeather(CloudySVG)
    }
    if (targetWeather.indexOf('阴') !== -1) {
      setWeather(OverCastSVG)
    }
    if (targetWeather.indexOf('雪') !== -1) {
      setWeather(SnowSVG)
    }
    if (targetWeather.indexOf('晴') !== -1) {
      setWeather(SunSVG)
    }
    if (targetWeather.indexOf('雨') !== -1) {
      setWeather(RainSVG)
    }
    if (targetWeather.indexOf('雾') !== -1 || targetWeather.indexOf('霾') !== -1) {
      setWeather(FogSVG)
    }
    if (targetWeather.indexOf('风') !== -1) {
      setWeather(WindSVG)
    }
  }, [props.weather])
  return (
    <>
      <img src={weather} className="weather-icon" alt="logo" />
      <div
        className={'font-ten'}
        style={{
          color: 'white',
          marginLeft: 'calc(10px + 1vw)',
        }}
      >
        {props.weather}
      </div>
      <div
        className={'font-ten'}
        style={{
          color: '#3E8BF3',
          marginLeft: 'calc(10px + 1vw)',
        }}
      >
        {props.temp}
      </div>
    </>
  )
}
