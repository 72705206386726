import React from 'react'

import { DigitalFlop, Decoration10, Decoration3 } from '@jiaminghi/data-view-react'

import './DigitalFlop.less'

export default props => {
  let digitalFlopData = [
    {
      title: '收货数',
      number: {
        number: [props.data.monthly_received],
        content: '{nt}',
        textAlign: 'right',
        style: {
          fill: '#4d99fc',
          fontWeight: 'bold',
        },
      },
      unit: '单',
    },
    {
      title: '拣货数',
      number: {
        number: [props.data.monthly_picked],
        content: '{nt}',
        textAlign: 'right',
        style: {
          fill: '#4d99fc',
          fontWeight: 'bold',
        },
      },
      unit: '单',
    },
    {
      title: '发货数',
      number: {
        number: [props.data.monthly_delivered],
        content: '{nt}',
        textAlign: 'right',
        style: {
          fill: '#4d99fc',
          fontWeight: 'bold',
        },
      },
      unit: '单',
    },
    {
      title: '封箱数',
      number: {
        number: [props.data.monthly_packed],
        content: '{nt}',
        textAlign: 'right',
        style: {
          fill: '#4d99fc',
          fontWeight: 'bold',
        },
      },
      unit: '箱',
    },
    {
      title: '待收货',
      number: {
        number: [props.data.receiving],
        content: '{nt}',
        textAlign: 'right',
        style: {
          fill: '#f46827',
          fontWeight: 'bold',
        },
      },
      unit: '单',
    },
    {
      title: '待上架',
      number: {
        number: [props.data.putting],
        content: '{nt}',
        textAlign: 'right',
        style: {
          fill: '#f46827',
          fontWeight: 'bold',
        },
      },
      unit: '个',
    },
    {
      title: '待拣货',
      number: {
        number: [props.data.picking],
        content: '{nt}',
        textAlign: 'right',
        style: {
          fill: '#f46827',
          fontWeight: 'bold',
        },
      },
      unit: '单',
    },
    {
      title: '待发货',
      number: {
        number: [props.data.delivering],
        content: '{nt}',
        textAlign: 'right',
        style: {
          fill: '#f46827',
          fontWeight: 'bold',
        },
      },
      unit: '单',
    },
    // {
    //   title: '待推送',
    //   number: {
    //     number: [props.data.pushing],
    //     content: '{nt}',
    //     textAlign: 'right',
    //     style: {
    //       fill: '#f46827',
    //       fontWeight: 'bold',
    //     },
    //   },
    //   unit: '',
    // },
  ]
  return (
    <div id="digital-flop">
      <div className="flop-side-container">
        <div className="flop-left-title">
          {new Date().getFullYear()}年{new Date().getMonth() + 1}月
        </div>
        <div className="flop-container">
          {digitalFlopData.slice(0, 4).map((item, index) => (
            <div className="digital-flop-item" key={item.title}>
              <div className="digital-flop-title">{item.title}</div>
              <div className="digital-flop">
                <div className="number-unit-container">
                  <DigitalFlop
                    config={{ ...item.number, textAlign: 'center' }}
                    style={{ width: '100px', height: '50px' }}
                  />
                  {item.unit !== '' && <div className="unit">{item.unit}</div>}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="digital-flop-item decoration-title-container">
        <Decoration3 className="decoration-title" style={{ width: '350px', height: '50px' }} />
      </div>
      <div className="flop-side-container">
        <div className="flop-left-title">待办事项</div>
        <div className="flop-container">
          {digitalFlopData.slice(4, 8).map((item, index) => (
            <div className="digital-flop-item digital-flop-item-right" key={item.title}>
              <div className="digital-flop-title">{item.title}</div>
              <div className="digital-flop">
                <div className="number-unit-container">
                  <DigitalFlop
                    config={{ ...item.number, textAlign: 'center' }}
                    style={{ width: '100px', height: '50px' }}
                  />
                  {item.unit !== '' && <div className="unit">{item.unit}</div>}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Decoration10 />
    </div>
  )
}
