import React, { useEffect, useState } from 'react'
export default props => {
  let [time, setTime] = useState('')
  let [date, setDate] = useState('')
  let [day, setDay] = useState('')
  useEffect(() => {
    setInterval(() => {
      const fixZero = number => {
        if (number < 10) {
          return '0' + number
        }
        return number
      }
      setTime(
        `${new Date().getHours()}:${fixZero(new Date().getMinutes())}:${fixZero(
          new Date().getSeconds()
        )}`
      )
      setDate(
        `${new Date().getFullYear()}年${new Date().getMonth() + 1}月${new Date().getDate()}日`
      )
      setDay(`星期${['日', '一', '二', '三', '四', '五', '六'][new Date().getDay()]}`)
    }, 1000)
  }, [])
  return (
    <>
      <div
        className={'font-ten'}
        style={{
          color: '#3E8BF3',
          marginLeft: 'calc(10px + 1vw)',
        }}
      >
        {date}
      </div>
      <div
        className={'font-ten'}
        style={{
          color: '#3E8BF3',
          marginLeft: 'calc(10px + 1vw)',
        }}
      >
        {day}
      </div>
      <div
        className={'font-ten'}
        style={{
          color: '#E9F9FE',
          width: 'calc(30px + 2vw)',
          marginLeft: 'calc(10px + 1vw)',
        }}
      >
        {time}
      </div>
    </>
  )
}
