import React from 'react'
import './style.less'
import Weather from './weather'
import Title from './title'
import Time from './time'
import Scope from './scope'
import Temp from './temp'
import Batch from './batch'
import Reagent from './reagent'
import Comsume from './comsume'
export default props => {
  return (
    <div className={'no-10-page-container'}>
      <div className={'no-10-top-bar'}>
        <div className={'weather-container'}>
          <Weather weather={'多云'} temp={'15-25°C'} />
        </div>
        <div className={'font-pm main-text'}>
          <span>体外诊断试剂SPD管理系统</span>
        </div>
        <div className={'time-container'}>
          <Time />
        </div>
      </div>
      <Scope />
      <Temp />
      <div
        className={'tables-container'}
        style={{
          marginBottom: '0.02vh',
        }}
      >
        <Batch />
        <Reagent />
      </div>
      <Comsume />
    </div>
  )
}
