import React, { useContext, useEffect, useState } from 'react'
import { DataContext } from '../../App'
import Title from './title'
export default props => {
  let depList = [
    '发热组',
    '临检组',
    '门诊组',
    '急诊组',
    '生化组',
    '免疫组',
    '微生物组',
    '分子细胞组',
    '分子基因组',
  ]
  let data = useContext(DataContext)
  let count = depList.length
  let final = depList.map(function(item) {
    let alert = false
    if (Array.isArray(data.inventory_alarm)) {
      data.inventory_alarm.forEach(function(d) {
        if (d.scope__parent__name === item && d.quantity < d.alert_quantity) {
          if (alert === false) {
            count -= 1
          }
          alert = true
        }
      })
    } else {
      alert = undefined
      count = '-'
    }
    let text
    let img
    if (alert) {
      img = <img src={'/img/10/group/alert/' + item.replace('组', '') + '预警_icon@2x.png'} />
      text = (
        <div className={'font-ten scope-alert-text'}>
          {item} <span className={'font-source alert-status-text'}>(预警)</span>
        </div>
      )
    } else if (typeof alert === 'undefined') {
      img = <img src={'/img/10/group/' + item + '_icon@2x.png'} />
      text = (
        <div className={'font-ten'}>
          {item} <span className={'font-source alert-status-text'}>(-)</span>
        </div>
      )
    } else {
      img = <img src={'/img/10/group/' + item + '_icon@2x.png'} />
      text = (
        <div className={'font-ten'}>
          {item} <span className={'font-source alert-status-text'}>(正常)</span>
        </div>
      )
    }
    return {
      img: img,
      text: text,
      title: item,
      alert: alert,
    }
  })
  return (
    <>
      <div className={'chart-container'}>
        <div className={'line-container'}>
          <div></div>
        </div>
        <Title title={'班组库存预警提醒'} image={'/img/10/stock.png'} />
        <div className={'count-container'}>
          <div className={'count-title'}>
            库存安全：<span className={'count-count font-ten'}>{count}</span>个
          </div>
          <div className={'count-title'}>
            库存预警：
            <span
              className={'count-count font-ten'}
              style={{
                color: '#FFBF00',
              }}
            >
              {count === '-' ? '-' : depList.length - count}
            </span>
            个
          </div>
        </div>
        <div className={'chart-main'}>
          <div className={'dep-list-container'}>
            {final.map(function(item) {
              return (
                <div className={item.alert ? 'dep-alert-item' : ''}>
                  {item.img}
                  {item.text}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}
