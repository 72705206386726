import React from 'react'

import { Decoration5, Decoration7, Decoration8 } from '@jiaminghi/data-view-react'

import './Error.less'

let error_svg = `<svg version="1.1" id="Capa_1" x="0px" y="0px"
                     viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;">
<circle style="fill:#D75A4A;" cx="25" cy="25" r="25"/>
                    <polyline style="fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;"
                              points="16,34 25,25 34,16
\t"/>
                    <polyline style="fill:none;stroke:#FFFFFF;stroke-width:2;stroke-linecap:round;stroke-miterlimit:10;"
                              points="16,16 25,25 34,34
\t"/>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
                    <g>
</g>
</svg>`
let html = { __html: error_svg }
export default props => {
  return (
    <div id="error-container">
      <div id="error-container-icon" dangerouslySetInnerHTML={html} />

      <div id="error-container-text">
        {props.data.message}
        <br />
        {props.data.fp}
      </div>
    </div>
  )
}
