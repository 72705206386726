import React, { forwardRef, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import useAutoResize from '../resize/use/autoResize'

var Decoration = forwardRef(function(_ref, ref) {
  var defaultColor = ['rgba(11,41,109, 0.8)', '#33FFFF']
  if (_ref.theme) {
    defaultColor = ['rgba(11,41,109, 0.8)', '#537bc4']
  }

  var _useAutoResize = useAutoResize(ref),
    width = _useAutoResize.width,
    height = _useAutoResize.height,
    domRef = _useAutoResize.domRef
  // 主要条
  let offset = [40, 40]
  var mainLinePoints = [
    [0, offset[1]],
    [offset[0], 0],
    [width - offset[0], 0],
    [width, offset[1]],
    [width, height - offset[1]],
    [width - offset[0], height],
    [offset[0], height],
    [0, height - offset[1]],
    [0, offset[1]],
  ]
  mainLinePoints = mainLinePoints
    .map(function(point) {
      return point.join(',')
    })
    .join(' ')
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
      ref={domRef}
    >
      <div
        style={{
          position: 'relative',
          zIndex: 10,
        }}
      >
        {_ref.children}
      </div>
      <div
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
        }}
      >
        <svg width={width} height={height} version="1.1" xmlns="http://www.w3.org/2000/svg">
          <rect
            x={5}
            y={5}
            fill={defaultColor[0]}
            stroke={defaultColor[1]}
            strokeWidth="3"
            height={height - 10}
            width={width - 10}
            rx={offset[1]}
            ry={offset[0]}
          ></rect>
          <text x={50} y={30} fill={'white'} className={'border-title-main'}>
            {_ref.title}
          </text>
        </svg>
      </div>
    </div>
  )
})

export default Decoration
