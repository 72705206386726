import React from 'react'

import { Decoration7, ScrollBoard } from '@jiaminghi/data-view-react'

import './ReceiveNotification.less'
import doneSVG from '../svg/done.svg'
export const ReceiveNotification = props => {
  props.data.receiving = [
    {
      name: 'ZX1221020004',
      creation_date: '2022-10-20 02:27:22.815852+00:00',
      barcode_count: 2,
      batch_count: 2,
    },
    {
      name: 'ZX1221020003',
      creation_date: '2022-10-20 02:01:01.815852+00:00',
      barcode_count: 10,
      batch_count: 2,
    },
    {
      name: 'ZX1221020002',
      creation_date: '2022-10-20 01:27:01.815852+00:00',
      barcode_count: 33,
      batch_count: 4,
    },
    {
      name: 'ZX1221020001',
      creation_date: '2022-10-20 01:24:54.671509+00:00',
      barcode_count: 160,
      batch_count: 10,
    },
  ]
  let converted_data = props.data.receiving.map(item => {
    return [
      item.name,
      item.batch_count,
      item.barcode_count,
      new Date(item.creation_date).toLocaleString('zh-CN', {
        timeZone: 'Asia/Shanghai',
        hour12: false,
      }), // item.creation_date.split('.')[0].replace('T', ' '),
    ]
  })
  let total_rows = 12
  if (converted_data.length < total_rows) {
    for (let i = 0; i < total_rows; i++) {
      if (converted_data.length < i + 1) {
        converted_data.push(['', '', '', ''])
      }
    }
  }
  let config = {
    //header: ['单号', '已发货数', '应发货数', '发货进度', '', '配送医院', '制单时间'],
    header: ['单号', '品项数', '条码数', '创建时间'],
    data: converted_data,
    index: true,
    indexHeader: '序号',
    //columnWidth: [75, 210, 100, 100, 100, 80, 200, 200],
    columnWidth: [75, 200, 150, 150, 200],
    align: ['center', 'center', 'center', 'center', 'center'],
    rowNum: total_rows,
    headerBGC: '#1981f6',
    headerHeight: 45,
    oddRowBGC: 'rgba(0, 44, 81, 0.8)',
    evenRowBGC: 'rgba(10, 29, 50, 0.8)',
  }
  return (
    <div className="scroll-board receive-receipts-board">
      <Decoration7 style={{ height: '50px', fontSize: '20px' }}>
        <span>{props.type}</span>
      </Decoration7>
      {props.data.receiving.length === 0 && (
        <div className={'complete-svg'}>
          <img src={doneSVG} className="svg-icon" alt="logo" />
          <div>全部完成</div>
        </div>
      )}
      {props.data.receiving.length !== 0 && <ScrollBoard config={config} />}
    </div>
  )
}
