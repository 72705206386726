import React, { useEffect, useState, createContext } from 'react'
import DataV from './components/cangku'
import ZhuChang from './components/zhuchang'
import Keshi from './components/keshi'
import CangkuDaily from './components/cangku_daily'
import No10 from './components/No10'
import './App.css'
import { queryServer } from './service/request'
import Error from './components/common/Error'
import { FullScreenContainer } from '@jiaminghi/data-view-react'

export const DataContext = createContext({})

function getData(setDataFunction, type) {
  if (type.name === 'cangKu') {
    queryServer(
      type.domain + '/api/v2/probe/action/summary_statistics?field=depository',
      function(res) {
        setDataFunction(res.data)
      },
      type.fp
    )
  }
  if (type.name === 'zhuChang') {
    queryServer(
      type.domain + '/api/v2/probe/action/summary_statistics?field=hospital_storage',
      function(res) {
        setDataFunction(res.data)
      },
      type.fp
    )
  }

  if (type.name === 'keShi') {
    queryServer(
      type.domain + '/api/v2/probe/action/summary_statistics?field=hospital_department',
      function(res) {
        setDataFunction(res.data)
      },
      type.fp
    )
  }

  if (type.name === 'cangKu_daily') {
    queryServer(
      type.domain + '/api/v2/probe/action/summary_statistics?field=depository_todo_list',
      function(res) {
        setDataFunction(res.data)
      },
      type.fp
    )
  }

  if (type.name === 'shiYuan') {
    queryServer(
      type.domain + '/api/v2/probe/action/summary_statistics?field=laboratory_monitor',
      function(res) {
        res.data.request_time = new Date()
        setDataFunction(res.data)
      },
      type.fp
    )
  }
}

function App() {
  const [data, setData] = useState({})

  const [type, setType] = useState({
    name: '',
    domain: '',
    fp: '',
  })

  useEffect(() => {
    let final_obj = {
      name: '',
      domain: '',
      fp: '',
    }
    const urlParams = new URLSearchParams(window.location.search)
    const type = urlParams.get('type')
    // 指定类型
    if (type) {
      if (type.toUpperCase().indexOf('CK') >= 0) {
        final_obj.name = 'cangKu'
      }
      if (type.toUpperCase().indexOf('ZC') >= 0) {
        final_obj.name = 'zhuChang'
      }
      if (type.toUpperCase().indexOf('KS') >= 0) {
        final_obj.name = 'keShi'
      }
      if (type.toUpperCase().indexOf('CKD') >= 0) {
        final_obj.name = 'cangKu_daily'
      }
      if (type.toUpperCase().indexOf('SY') >= 0) {
        final_obj.name = 'shiYuan'
      }
    }

    // 手动指定域
    const currentDomain = urlParams.get('domain')
    if (currentDomain) {
      final_obj.domain = currentDomain
    }

    // 手动指定指纹
    const force_fp = urlParams.get('force_fp')
    if (force_fp) {
      final_obj.fp = force_fp
    }
    setType(final_obj)
  }, [])

  useEffect(() => {
    getData(setData, type)

    const timer = setInterval(() => {
      getData(setData, type)
    }, 60000 * 10)

    return () => clearInterval(timer)
  }, [type])

  return (
    <DataContext.Provider value={data}>
      <div id="app">
        {type.name === 'cangKu_daily' && (
          <div id="data-view">
            <FullScreenContainer>
              <CangkuDaily data={data} />
            </FullScreenContainer>
          </div>
        )}
        {type.name === 'cangKu' && <DataV data={data} />}
        {type.name === 'zhuChang' && <ZhuChang data={data} />}
        {type.name === 'keShi' && <Keshi data={data} />}
        {type.name === 'shiYuan' && <No10 data={data} />}
        {type.name === '' && (
          <div id="data-view">
            <FullScreenContainer>
              <Error
                data={{
                  message: '未指定大屏模式，请联系管理员',
                }}
              />
            </FullScreenContainer>
          </div>
        )}
        {type.domain === '' && (
          <div id="data-view">
            <FullScreenContainer>
              <Error
                data={{
                  message: '未指定域，请联系管理员',
                }}
              />
            </FullScreenContainer>
          </div>
        )}
      </div>
    </DataContext.Provider>
  )
}

export default App
