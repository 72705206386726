import React, { useEffect, useState } from 'react'

import { Decoration7, ScrollBoard } from '@jiaminghi/data-view-react'

import { queryServer } from '../../../service/request'

export const SendNotification = props => {
  let svg = (
    <svg
      id="Layer_1"
      enable-background="new 0 0 468 468"
      height="512"
      viewBox="0 0 468 468"
      width="512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <g>
          <g>
            <path
              d="m334.419 441.105h-288.984c-11.822 0-21.406-9.597-21.406-21.436v-365.592c0-11.839 9.584-21.436 21.406-21.436h288.984c11.822 0 21.406 9.597 21.406 21.436v365.593c0 11.838-9.584 21.435-21.406 21.435z"
              fill="#fff"
            />
            <path
              d="m66.771 111.124v-78h-21.337c-11.822 0-21.406 9.116-21.406 20.954v365.592c0 11.838 9.584 21.618 21.406 21.618h288.984c11.822 0 21.931-9.779 21.931-21.618v-18.985c-159.929 0-289.578-129.641-289.578-289.561z"
              fill="#e6e7e8"
            />
            <g fill="#3e4d6c">
              <path d="m266.833 457.132h-221.398c-20.642 0-37.435-16.805-37.435-37.462v-365.593c0-20.657 16.793-37.463 37.435-37.463h36.259v32.055h-36.259c-2.966 0-5.378 2.426-5.378 5.408v365.593c0 2.982 2.413 5.408 5.378 5.408h221.398z" />
              <path d="m371.853 272.116h-32.057v-218.039c0-2.982-2.413-5.408-5.378-5.408h-33.625v-32.055h33.625c20.642 0 37.435 16.806 37.435 37.463z" />
            </g>
          </g>
          <path
            d="m252.2 64.307h-124.547c-9.941 0-18-8.059-18-18v-28.307c0-9.941 8.059-18 18-18h124.547c9.941 0 18 8.059 18 18v28.307c0 9.941-8.059 18-18 18z"
            fill="#0795fe"
          />
          <g>
            <g>
              <path d="m195.308 245.118h115.718v32.055h-115.718z" fill="#3e4d6c" />
            </g>
            <g>
              <path d="m195.308 148.658h115.718v32.055h-115.718z" fill="#3e4d6c" />
            </g>
            <g>
              <path d="m195.308 341.578h54.811v32.055h-54.811z" fill="#3e4d6c" />
            </g>
          </g>
        </g>
        <g>
          <path
            id="XMLID_6149_"
            d="m290.05 367.087c-8.052 46.599 23.182 91.167 69.763 99.545 46.581 8.379 90.87-22.605 98.922-69.204s-23.182-91.167-69.763-99.545-90.87 22.605-98.922 69.204z"
            fill="#0795fe"
          />
        </g>
        <path
          d="m353.927 415.026-29.001-29.524 22.847-22.51 17.534 17.805 35.181-36.031 22.933 22.392-46.603 47.825c-3 3.072-13.344 9.209-22.891.043z"
          fill="#fff"
        />
        <g fill="#3e4d6c">
          <path d="m93.641 389.156-31.127-33.901 23.624-21.647 19.318 21.073 45.919-50.036 23.617 21.675-57.731 62.839c-3.035 3.307-14.176 8.855-23.62-.003z" />
          <path d="m93.641 292.992-31.127-33.901 23.624-21.647 19.318 21.073 45.919-50.036 23.617 21.675-57.731 62.839c-3.035 3.306-14.176 8.854-23.62-.003z" />
          <path d="m93.641 195.759-31.127-33.901 23.624-21.647 19.318 21.073 45.919-50.036 23.617 21.675-57.731 62.839c-3.035 3.307-14.176 8.854-23.62-.003z" />
        </g>
      </g>
    </svg>
  )
  let config = {
    //header: ['单号', '已发货数', '应发货数', '发货进度', '', '配送医院', '制单时间'],
    header: ['单号', '品项数', '条码数', '配送医院'],
    data: props.data.delivering_receipts
      .filter(item => {
        return item.detail_count > 0 && item.quantity > 0
      })
      .map(item => {
        return [item.name, item.detail_count, item.quantity, item.organization]
      }),
    index: true,
    indexHeader: '序号',
    //columnWidth: [75, 210, 100, 100, 100, 80, 200, 200],
    columnWidth: [75, 400, 150, 150, 400],
    align: ['center', 'center', 'center', 'center', 'center'],
    rowNum: 7,
    headerBGC: '#1981f6',
    headerHeight: 45,
    oddRowBGC: 'rgba(0, 44, 81, 0.8)',
    evenRowBGC: 'rgba(10, 29, 50, 0.8)',
  }
  return (
    <div className="scroll-board">
      <Decoration7 style={{ height: '50px', fontSize: '20px' }}>
        <span>{props.type}</span>
      </Decoration7>
      {props.data.delivering_receipts.length === 0 && (
        <div className={'complete-svg'}>
          {svg}
          <div>全部完成</div>
        </div>
      )}
      {props.data.delivering_receipts.length !== 0 && <ScrollBoard config={config} />}
    </div>
  )
}
