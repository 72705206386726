import { Charts, Decoration3 } from '@jiaminghi/data-view-react'
import React from 'react'
import './ChargeAndComsume.less'
import calendarSVG from '../svg/calendar.svg'

export default props => {
  let colors = [
    '#1f77b4',
    '#aec7e8',
    '#ff7f0e',
    '#ffbb78',
    '#2ca02c',
    '#98df8a',
    '#d62728',
    '#ff9896',
    '#9467bd',
    '#c5b0d5',
    '#8c564b',
    '#c49c94',
    '#e377c2',
    '#f7b6d2',
    '#7f7f7f',
    '#c7c7c7',
    '#bcbd22',
    '#dbdb8d',
    '#17becf',
    '#9edae5',
  ]
  let getColor = function(index) {
    try {
      return colors[index]
    } catch (e) {
      return '#ffffff'
    }
  }
  let data = []
  let temp_data = props.data.revenue
  temp_data.map(item => {
    data.push({
      creation_date: item.datetime,
      creator: '日收费',
      id__count: item.laboratory.bill + item.nuclear_medicine.bill + item.other.bill,
    })
    data.push({
      creation_date: item.datetime,
      creator: '日消耗',
      id__count: item.laboratory.cost + item.nuclear_medicine.cost + item.other.cost,
    })
  })

  let line_data = {}
  let date_arr = []
  let max_num = 0
  data.forEach(item => {
    if (line_data.hasOwnProperty(item.creator)) {
      line_data[item.creator][item.creation_date] = item.id__count
    } else {
      line_data[item.creator] = {
        [item.creation_date]: item.id__count,
      }
    }
    if (!date_arr.includes(item.creation_date)) {
      date_arr.push(item.creation_date)
    }
    if (item.id__count > max_num) {
      max_num = item.id__count
    }
  })

  // 填充日期
  let finalDate = []
  try {
    date_arr.sort((a, b) => {
      return new Date(a) - new Date(b)
    })
    if (date_arr.length >= 2) {
      let startDate = date_arr[0]
      let endDate = date_arr[date_arr.length - 1]
      let dateMove = new Date(startDate)
      let strDate = startDate

      while (strDate < endDate) {
        strDate = dateMove.toISOString().slice(0, 10)
        finalDate.push(strDate)
        dateMove.setDate(dateMove.getDate() + 1)
      }
    } else {
      finalDate = date_arr
    }
  } catch (e) {
    console.log(e)
    finalDate = date_arr
  }

  for (let key in line_data) {
    finalDate.forEach(item => {
      if (!line_data[key].hasOwnProperty(item)) {
        line_data[key][item] = 0
      }
    })
  }
  let legend_data = []
  let series_data = []
  let index = 0
  for (let key in line_data) {
    legend_data.push({
      name: key,
      color: getColor(index * 2),
    })
    series_data.push({
      name: key,
      data: finalDate.map(item => {
        return line_data[key][item]
      }),
      type: 'line',
      // smooth: true,
      lineStyle: {
        stroke: colors[index * 2],
      },
      lineArea: {
        show: true,
        gradient: [colors[index * 2], colors[index * 2 + 1]],
      },
      linePoint: {
        radius: 4,
        style: {
          fill: colors[index * 2],
          stroke: 'transparent',
        },
      },
    })
    index += 1
  }
  // if (finalDate.length >= 15) {
  //   finalDate = finalDate.map((item, index) => {
  //     if (index % 2 === 0) {
  //       return item
  //     } else {
  //       return ''
  //     }
  //   })
  // }

  let option = {
    legend: {
      data: legend_data,
      textStyle: {
        fill: '#fff',
        fontSize: 18,
      },
    },
    xAxis: {
      name: '',
      nameTextStyle: {
        fill: '#FFF',
      },
      data: finalDate,
      axisLine: {
        style: {
          stroke: 'rgba(255, 255, 255, 0.3)',
          fontSize: 12,
        },
      },
      axisLabel: {
        style: {
          fill: '#FFF',
          fontSize: 12,
        },
        formatter: function(x) {
          return x.value.replace(x.value.split('-')[0] + '-', '')
        },
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      splitLine: {
        style: {
          stroke: 'rgba(255, 255, 255, 0.3)',
        },
        show: true,
      },
      nameTextStyle: {
        fill: '#FFF',
        fontSize: 18,
      },
      data: 'value',
      axisLine: {
        style: {
          stroke: 'rgba(255, 255, 255, 0.3)',
          fontSize: 15,
        },
      },
      axisLabel: {
        style: {
          fill: '#FFF',
          fontSize: 18,
        },
        formatter: function(x) {
          return x.value / 10000 + '万'
        },
      },
      axisTick: {
        show: false,
      },
      min: 0,
      max: Math.ceil(max_num / 100000) * 100000,
    },
    series: series_data,
  }
  return (
    <div className="charge-and-consume-container">
      {props.data.revenue.length !== 0 && (
        <>
          {Boolean(false) && (
            <div className="chart-name">
              <div className="top-title">
                月累计收费:&nbsp;
                <span>
                  {Math.round(
                    props.data.revenue.reduce((prev, curr) => {
                      return (
                        prev + curr.laboratory.bill + curr.nuclear_medicine.bill + curr.other.bill
                      )
                    }, 0) / 10000
                  ).toLocaleString('en-US') + '万'}
                </span>
              </div>
              <div className="top-title">
                综合成本率:&nbsp;
                <span>
                  {props.data.cost_rate ? (props.data.cost_rate * 100).toFixed(2) + '%' : '-'}
                </span>
              </div>
            </div>
          )}
          <Charts option={option} />
        </>
      )}
      {props.data.revenue.length === 0 && (
        <div className={'complete-svg'}>
          <img src={calendarSVG} className="svg-icon" alt="logo" />
          <div>本月暂无数据</div>
        </div>
      )}
    </div>
  )
}
