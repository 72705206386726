import { Charts, Decoration3 } from '@jiaminghi/data-view-react'
import './IncomeCostAnalysis.less'
import React from 'react'
import calendarSVG from '../svg/calendar.svg'
export default props => {
  let max_num = 0
  props.data.statements.charge = [
    { scope: '生化室', quantity: 898448 },
    { scope: '门诊', quantity: 866522 },
    { scope: '急诊室', quantity: 3262147 },
    { scope: '分子室', quantity: 21300 },
    { scope: '免疫室', quantity: 264427 },
    { scope: '血库', quantity: 24125 },
    { scope: '细菌室', quantity: 103750 },
  ]
  props.data.statements.cost = [
    { scope: '生化室', quantity: 194064.77 },
    { scope: '门诊', quantity: 216320 },
    { scope: '急诊室', quantity: 906550.65 },
    { scope: '分子室', quantity: 9670.2 },
    { scope: '免疫室', quantity: 103919.81 },
    { scope: '血库', quantity: 8443.75 },
    { scope: '细菌室', quantity: 46791.25 },
  ]
  let temp_data = props.data.statements.charge.map(function(item) {
    props.data.statements.cost.forEach(costItem => {
      if (costItem.scope === item.scope) {
        item.cost = parseFloat(costItem.quantity)
      }
    })
    if (!item.hasOwnProperty('cost')) {
      item.cost = 0
    }
    item.charge = parseFloat(item.quantity)
    if (item.charge > max_num) {
      max_num = item.charge
    }
    return item
  })
  temp_data.sort((a, b) => {
    return b.cost + b.charge - (a.cost + a.charge)
  })

  // 名称过长缩减
  try {
    temp_data = temp_data.map(item => {
      if (item.scope.length >= 6) {
        item.scope = item.scope.substring(0, 4) + '...'
      }
      return item
    })
  } catch (e) {
    console.log(e)
  }

  const option = {
    legend: {
      data: ['成本', '净收入'],
      textStyle: {
        fill: '#FFF',
      },
    },
    color: ['#ffbb78', '#aec7e8'],
    xAxis: {
      name: '',
      data: temp_data.map(item => item.scope),
      axisLabel: {
        style: {
          fill: '#FFF',
          fontSize: 12,
        },
      },
    },
    yAxis: {
      name: '',
      data: 'value',
      axisLabel: {
        style: {
          fill: '#FFF',
          fontSize: 12,
        },
        formatter: function(x) {
          return x.value / 10000 + '万'
        },
      },
      splitLine: {
        style: {
          stroke: 'rgba(255, 255, 255, 0.3)',
        },
      },
      min: 0,
      max: Math.ceil(max_num / 100000) * 100000,
    },
    series: [
      {
        name: '成本',
        data: temp_data.map(item => item.cost),
        type: 'bar',
        stack: 'a',
      },
      {
        name: '净收入',
        data: temp_data.map(item => item.charge - item.cost),
        type: 'bar',
        stack: 'a',
        label: {
          show: true,
          position: 'top',
          offset: [0, -10],
          style: {
            fill: '#fff',
          },
          formatter: function(x) {
            return (
              String(
                ((temp_data[x.index].cost / temp_data[x.index].charge).toFixed(3) * 100).toFixed(1)
              ) + '%'
            )
          },
        },
      },
    ],
  }

  return (
    <div className="income-cost-analysis-chart">
      <div className="chart-name">
        {props.hasOwnProperty('svg') && props.svg}
        <div>
          <span style={{ fontSize: '20px' }}>科室收入分析</span>
          <Decoration3
            style={{
              width: '200px',
              height: '20px',
            }}
          />
        </div>
      </div>
      {max_num !== 0 && <Charts className="zhuChang-pie-cost" option={option} />}
      {max_num === 0 && props.data.statements.cost.length === 0 && (
        <div className={'complete-svg'}>
          <img src={calendarSVG} className="svg-icon" alt="logo" />
          <div>本月暂无数据</div>
        </div>
      )}
    </div>
  )
}
