import React from 'react'

import { DigitalFlop, Decoration10, Decoration3 } from '@jiaminghi/data-view-react'

import './DigitalFlop.less'
import Pie from './Pie'
import wareHouseSVG from '../svg/warehouse.svg'
import expireSVG from '../svg/expired.svg'

export default props => {
  let digitalFlopData = [
    {
      title: '品项数',
      number: {
        number: [props.data.primary_inventory.product],
        content: '{nt}',
        textAlign: 'right',
        style: {
          fill: '#4d99fc',
          fontWeight: 'bold',
        },
      },
      unit: '个',
    },
    {
      title: '批号数',
      number: {
        number: [props.data.primary_inventory.batch],
        content: '{nt}',
        textAlign: 'right',
        style: {
          fill: '#4d99fc',
          fontWeight: 'bold',
        },
      },
      unit: '个',
    },
    {
      title: '条码数',
      number: {
        number: [props.data.primary_inventory.barcode],
        content: '{nt}',
        textAlign: 'right',
        style: {
          fill: '#4d99fc',
          fontWeight: 'bold',
        },
      },
      unit: '个',
    },
  ]
  return (
    <div id="digital-flop">
      <div className="flop-side-container">
        <div className="flop-left-title">
          <img src={wareHouseSVG} className="svg-icon" alt="logo" />
          一级库库存
        </div>
        <div className="flop-container">
          {digitalFlopData.slice(0, 3).map((item, index) => (
            <div className="digital-flop-item" key={item.title}>
              <div className="digital-flop-title">{item.title}</div>
              <div className="digital-flop">
                <div className="number-unit-container">
                  <DigitalFlop
                    config={{ ...item.number, textAlign: 'center' }}
                    style={{ width: '100px', height: '50px' }}
                  />
                  {item.unit !== '' && <div className="unit">{item.unit}</div>}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="digital-flop-item decoration-title-container">
        <Decoration3 className="decoration-title" style={{ width: '350px', height: '50px' }} />
      </div>
      <div className="flop-side-container-pie">
        <div className="flop-left-title-pie">
          <img src={expireSVG} className="svg-icon" alt="logo" />
          试剂效期情况
        </div>
        <div className="flop-container">
          <Pie data={props.data} />
        </div>
        {/*<div className="flop-left-title-pie-hint">*/}
        {/*  近效期品项数: {props.data.mock.number4}*/}
        {/*</div>*/}
      </div>
      <Decoration10 />
    </div>
  )
}
