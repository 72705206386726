import React from 'react'

import { Decoration3, FullScreenContainer, Loading } from '@jiaminghi/data-view-react'

import TopHeader from '../common/TopHeader'

import './index.less'
import Error from '../common/Error'
import TopReagent from './TopReagent'
import ChargeAndComsume from './ChargeAndComsume'
import CostPie from './CostPie'
import IncomeCostAnalysis from './IncomeCostAnalysis'
import IncomeCostStatistics from './IncomeCostStatistics'
import expireSVG from '../svg/expired.svg'
import barSVG from '../svg/financial.svg'
import CrcLogo from '../cangku/img/crc-logo.png'
import medicineSVG from '../svg/medicine.svg'
import { ReagentList } from './ReagentList'
import analyticsSVG from '../svg/analytics.svg'
import Pie from './Pie'

export default props => {
  return (
    <div id="data-view" className="data-view-keShi">
      <FullScreenContainer>
        {Object.keys(props.data).length === 0 && <Loading>Loading...</Loading>}
        {Object.keys(props.data).length === 2 && <Error data={props.data} />}

        {Object.keys(props.data).length >= 3 && (
          <>
            <TopHeader title={'科室IVD数据看板'} />
            <ChargeAndComsume data={props.data} />
            <div className="ks-main-content">
              <div className="ks-main-content-left">
                <CostPie
                  data={props.data}
                  svg={<img src={expireSVG} className="svg-icon" alt="logo" />}
                />
                <div className="ks-main-content-right-list-container">
                  <div className="chart-name">
                    <img src={medicineSVG} className="svg-icon" alt="logo" />
                    <div>
                      <span style={{ fontSize: '20px' }}>临期试剂</span>
                      <Decoration3
                        style={{
                          width: '200px',
                          height: '20px',
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <ReagentList data={props.data} />
                  </div>
                </div>
              </div>
              <div className="ks-main-content-right">
                <IncomeCostAnalysis
                  data={props.data}
                  svg={<img src={barSVG} className="svg-icon" alt="logo" />}
                />
                {/*<div className="ks-main-content-right-pie-container">*/}
                {/*  <div className="chart-name">*/}
                {/*    <img src={analyticsSVG} className="svg-icon" alt="logo" />*/}
                {/*    <div>*/}
                {/*      <span style={{ fontSize: '20px' }}>月成本占比</span>*/}
                {/*      <Decoration3*/}
                {/*        style={{*/}
                {/*          width: '200px',*/}
                {/*          height: '20px',*/}
                {/*        }}*/}
                {/*      />*/}
                {/*    </div>*/}
                {/*  </div>*/}
                {/*  <div>*/}
                {/*    <Pie data={props.data} />*/}
                {/*  </div>*/}
                {/*</div>*/}
              </div>
            </div>

            <div className="right-logo-container">
              <img className="right-logo-img" src={CrcLogo} alt="logo" />
              <div className="right-logo-title">华润智检（上海）医疗器械有限公司</div>
            </div>
          </>
        )}
      </FullScreenContainer>
    </div>
  )
}
