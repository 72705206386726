import React from 'react'

import { FullScreenContainer, Loading } from '@jiaminghi/data-view-react'

import TopHeader from '../common/TopHeader'

import './index.less'
import Error from '../common/Error'
import DigitalFlop from './DigitalFlop'
import StockLine from './StockLine'
import Alert from './Alert'
import clipboardSVG from '../svg/clipboard.svg'
import demandSVG from '../svg/demand.svg'
import { ReceiveNotification } from './ReceiveNotification'
import CrcLogo from '../cangku/img/crc-logo.png'

export default props => {
  return (
    <div id="data-view" className="data-view-zhuChang">
      <FullScreenContainer>
        {Object.keys(props.data).length === 0 && <Loading>Loading...</Loading>}
        {Object.keys(props.data).length === 2 && <Error data={props.data} />}

        {Object.keys(props.data).length >= 3 && (
          <>
            <TopHeader title={'仓库IVD数据看板'} />
            <div className="main-content">
              <DigitalFlop data={props.data} />
              <div className="block-left-right-content">
                <div className="block-top-bottom-content">
                  {/*<ScanLine className="scanLine-pzx" data={props.data} title={"扫描统计"}*/}
                  {/*svg={<img src={scanSVG} className="svg-icon" alt="logo" />}*/}
                  {/*/>*/}
                  <StockLine
                    data={props.data}
                    svg={<img src={clipboardSVG} className="svg-icon" alt="logo" />}
                  />
                  <Alert
                    data={props.data}
                    svg={<img src={demandSVG} className="svg-icon" alt="logo" />}
                  />
                  <ReceiveNotification data={props.data} type={'待收货'} />
                </div>
                {props.data.message && (
                  <div className="marquee">
                    <span>{props.data.message}</span>
                  </div>
                )}
              </div>
              <div className="right-logo-container">
                <img className="right-logo-img" src={CrcLogo} alt="logo" />
                <div className="right-logo-title">华润智检（上海）医疗器械有限公司</div>
              </div>
            </div>
          </>
        )}
      </FullScreenContainer>
    </div>
  )
}
