import React, { useEffect, useState } from 'react'
export default props => {
  return (
    <>
      <div className={'side-title-container'}>
        <div>
          <img src={props.image} />
        </div>
        <div className={'side-title-text font-you-she'}>{props.title}</div>
      </div>
    </>
  )
}
