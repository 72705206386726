import React, { forwardRef, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import useAutoResize from './resize/use/autoResize'
import FullscreenSVG from '../svg/maximize.svg'
import NormalScreenSVG from '../svg/minimize.svg'
import CloudySVG from '../svg/weather/cloudy.svg'
import SnowSVG from '../svg/weather/snow.svg'
import OverCastSVG from '../svg/weather/overcast.svg'
import RainSVG from '../svg/weather/rain.svg'
import FogSVG from '../svg/weather/fog.svg'
import SunSVG from '../svg/weather/sun.svg'
import WindSVG from '../svg/weather/wind.svg'
import './style.less'

var Decoration = forwardRef(function(_ref, ref) {
  var isInFullScreen =
    (document.fullscreenElement && true) ||
    (document.webkitFullscreenElement && true) ||
    (document.mozFullScreenElement && true) ||
    (document.msFullscreenElement && true)
  let full = () => {
    isInFullScreen =
      (document.fullscreenElement && true) ||
      (document.webkitFullscreenElement && true) ||
      (document.mozFullScreenElement && true) ||
      (document.msFullscreenElement && true)
    var docElm = document.documentElement
    if (!isInFullScreen) {
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen()
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen()
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen()
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen()
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }
    }
  }

  // 时间
  let [time, setTime] = useState('')
  let [date, setDate] = useState('')
  let [day, setDay] = useState('')
  let [weather, setWeather] = useState(undefined)
  useEffect(() => {
    const refresh = () => {
      const fixZero = number => {
        if (number < 10) {
          return '0' + number
        }
        return number
      }
      setTime(`${new Date().getHours()}:${fixZero(new Date().getMinutes())}`)
      setDate(
        `${new Date().getFullYear()}年${new Date().getMonth() + 1}月${new Date().getDate()}日`
      )
      setDay(`星期${['日', '一', '二', '三', '四', '五', '六'][new Date().getDay()]}`)
      try {
        if (
          _ref.weather &&
          Array.isArray(_ref.weather.lives) &&
          _ref.weather.lives.length >= 1 &&
          _ref.weather.lives[0].weather
        ) {
          let targetWeather = _ref.weather.lives[0].weather
          if (targetWeather.indexOf('多云') !== -1) {
            setWeather(CloudySVG)
          }
          if (targetWeather.indexOf('阴') !== -1) {
            setWeather(OverCastSVG)
          }
          if (targetWeather.indexOf('雪') !== -1) {
            setWeather(SnowSVG)
          }
          if (targetWeather.indexOf('晴') !== -1) {
            setWeather(SunSVG)
          }
          if (targetWeather.indexOf('雨') !== -1) {
            setWeather(RainSVG)
          }
          if (targetWeather.indexOf('雾') !== -1 || targetWeather.indexOf('霾') !== -1) {
            setWeather(FogSVG)
          }
          if (targetWeather.indexOf('风') !== -1) {
            setWeather(WindSVG)
          }
        }
      } catch (e) {
        console.log(e)
      }
    }
    setTimeout(() => {
      refresh()
    }, 100)
    const interval = setInterval(refresh, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [_ref])

  var _useAutoResize = useAutoResize(ref),
    width = _useAutoResize.width,
    height = _useAutoResize.height,
    domRef = _useAutoResize.domRef
  // 主要条
  var mainLinePoints = [
    [0, height * 0.6],
    [width * 0.135, height * 0.6],
    [width * 0.15, height * 0.8],
    [width * 0.421, height * 0.8],
    [width * 0.427, height * 0.88],
    [width * 0.434, height * 0.88],
    [width * 0.441, height * 0.96],
    [width * 0.5, height * 0.96],
  ]
  var reversePoints = []
  mainLinePoints.forEach(item => {
    reversePoints.unshift([width - item[0], item[1]])
  })
  mainLinePoints = mainLinePoints.concat(reversePoints)
  mainLinePoints = mainLinePoints
    .map(function(point) {
      return point.join(',')
    })
    .join(' ')

  // 次要装饰矩形
  let leftRectProps = {
    x: 0 - 10,
    y: height * 0.6 - 5,
    width: width * 0.125,
    height: 10,
    rx: 5,
    ry: 5,
    fill: 'url(#LeftGradient)',
  }
  let rightRectProps = {
    x: width - width * 0.125,
    y: height * 0.6 - 5,
    width: width * 0.125 + 10,
    height: 10,
    rx: 5,
    ry: 5,
    fill: 'url(#RightGradient)',
  }
  let leftSideRectProps = {
    x: width * 0.348,
    y: height * 0.8 - 4,
    width: width * 0.066,
    height: 8,
    rx: 5,
    ry: 5,
    fill: 'url(#LeftSideGradient)',
  }
  let rightSideRectProps = {
    x: width * 0.595,
    y: height * 0.8 - 4,
    width: width * 0.066,
    height: 8,
    rx: 5,
    ry: 5,
    fill: 'url(#RightSideGradient)',
  }
  let middleSideRectProps = {
    x: width * 0.466,
    y: height * 0.96 - 4,
    width: width - width * 0.466 * 2,
    height: 8,
    rx: 5,
    ry: 5,
    fill: 'url(#MiddleSideGradient)',
  }

  // 两侧装饰条
  let leftTopLinePoints = [
    [width * 0.323, 0],
    [width * 0.343, height * 0.4],
    [width * 0.35, height * 0.4],
    [width * 0.355, height * 0.48],
  ]
  let rightTopLinePoints = leftTopLinePoints
    .map(item => {
      return [width - item[0], item[1]]
    })
    .map(function(point) {
      return point.join(',')
    })
    .join(' ')
  leftTopLinePoints = leftTopLinePoints
    .map(function(point) {
      return point.join(',')
    })
    .join(' ')
  let leftBottomLinePoints = [
    [width * 0.338, height * 0.4],
    [width * 0.343, height * 0.48],
    [width * 0.35, height * 0.48],
    [width * 0.365, height * 0.8],
  ]
  let rightBottomLinePoints = leftBottomLinePoints
    .map(item => {
      return [width - item[0], item[1]]
    })
    .map(function(point) {
      return point.join(',')
    })
    .join(' ')
  leftBottomLinePoints = leftBottomLinePoints
    .map(function(point) {
      return point.join(',')
    })
    .join(' ')

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
      ref={domRef}
    >
      <svg width={width} height={height} version="1.1" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient id="LeftGradient">
            <stop offset="0%" stopColor="#2E1D9B" />
            <stop offset="100%" stopColor="#29459C" />
          </linearGradient>
          <linearGradient id="RightGradient">
            <stop offset="0%" stopColor="#29459C" />
            <stop offset="100%" stopColor="#2E1D9B" />
          </linearGradient>
          <linearGradient id="LeftSideGradient">
            <stop offset="0%" stopColor="#1A81D0" />
            <stop offset="100%" stopColor="#31D2F7" />
          </linearGradient>
          <linearGradient id="RightSideGradient">
            <stop offset="0%" stopColor="#31D2F7" />
            <stop offset="100%" stopColor="#1A81D0" />
          </linearGradient>
          <linearGradient id="MiddleSideGradient">
            <stop offset="0%" stopColor="#1A81D0" />
            <stop offset="50%" stopColor="#31D2F7" />
            <stop offset="100%" stopColor="#1A81D0" />
          </linearGradient>
        </defs>

        <polyline
          fill="transparent"
          stroke="#3363BC"
          strokeWidth="3"
          points={leftTopLinePoints}
        ></polyline>
        <polyline
          fill="transparent"
          stroke="#3363BC"
          strokeWidth="3"
          points={leftBottomLinePoints}
        ></polyline>
        <polyline
          fill="transparent"
          stroke="#3363BC"
          strokeWidth="3"
          points={rightBottomLinePoints}
        ></polyline>
        <polyline
          fill="transparent"
          stroke="#3363BC"
          strokeWidth="3"
          points={rightTopLinePoints}
        ></polyline>
        <polyline fill="transparent" stroke="#3363BC" strokeWidth="3" points={mainLinePoints}>
          {/*<animate attributeName="stroke-dasharray" attributeType="XML" from="0, 391.66653108874107, 0, 391.66653108874107" to="0, 0, 783.3330621774821, 0" dur="5s" begin="0s" calcMode="spline" keyTimes="0;1" keySplines="0.4,1,0.49,0.98" repeatCount="indefinite"></animate>*/}
        </polyline>
        <rect {...leftRectProps} />
        <rect {...rightRectProps} />
        <rect {...leftSideRectProps} />
        <rect {...rightSideRectProps} />
        <rect {...middleSideRectProps} />
      </svg>

      <div className={'top-header-container'}>
        <div className={'top-header-container-time'}>{time}</div>
        <div className={'top-header-container-date'}>
          <div>{day}</div>
          <div>{date}</div>
        </div>
        <div>{weather && <img src={weather} className="weather-icon" alt="logo" />}</div>
      </div>
      <div
        style={{
          position: 'absolute',
          left: width * 0.42,
          width: width - width * 0.42 * 2,
          top: '15px',
          fontSize: '45px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        今日待办
      </div>
      <div
        style={{
          position: 'absolute',
          right: '10px',
          top: '10px',
        }}
      >
        <img
          onClick={() => {
            full()
          }}
          src={isInFullScreen ? NormalScreenSVG : FullscreenSVG}
          className="fullscreen-icon"
          alt="logo"
        />
      </div>
    </div>
  )
})

export default Decoration
