import React, { forwardRef, useEffect, useLayoutEffect, useMemo, useState } from 'react'
import useAutoResize from '../resize/use/autoResize'
var defaultColor = ['#3f96a5', '#3f96a5']

var Decoration = forwardRef(function(_ref, ref) {
  var _useAutoResize = useAutoResize(ref),
    width = _useAutoResize.width,
    height = _useAutoResize.height,
    domRef = _useAutoResize.domRef
  // 主要条
  let offset = [80, 20]
  var mainLinePoints = [
    [0, offset[1]],
    [offset[0], 0],
    [width - offset[0], 0],
    [width, offset[1]],
    [width, height - offset[1]],
    [width - offset[0], height],
    [offset[0], height],
    [0, height - offset[1]],
    [0, offset[1]],
  ]
  mainLinePoints = mainLinePoints
    .map(function(point) {
      return point.join(',')
    })
    .join(' ')

  let start = [20, 20]
  // 四角装饰线
  let roundLines = {
    leftTop: [
      [offset[0] + start[0], 0],
      [offset[0], 0],
      [0, offset[1]],
      [0, offset[1] + start[1]],
    ],
    rightTop: [
      [width - (offset[0] + start[0]), 0],
      [width - offset[0], 0],
      [width, offset[1]],
      [width, offset[1] + start[1]],
    ],
    leftBottom: [
      [offset[0] + start[0], height],
      [offset[0], height],
      [0, height - offset[1]],
      [0, height - (offset[1] + start[1])],
    ],
    rightBottom: [
      [width - (offset[0] + start[0]), height],
      [width - offset[0], height],
      [width, height - offset[1]],
      [width, height - (offset[1] + start[1])],
    ],
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
      ref={domRef}
    >
      <div
        style={{
          position: 'relative',
          zIndex: 10,
        }}
      >
        {_ref.children}
      </div>
      <div
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
        }}
      >
        <svg width={width} height={height} version="1.1" xmlns="http://www.w3.org/2000/svg">
          <polyline
            fill="rgba(11,41,109, 0.8)"
            stroke="transparent"
            strokeWidth="3"
            points={mainLinePoints}
          ></polyline>
          {Object.keys(roundLines).map(item => {
            return (
              <polyline
                key={item}
                fill="transparent"
                stroke="#03C4DB"
                strokeWidth="2"
                points={roundLines[item]}
              ></polyline>
            )
          })}
        </svg>
      </div>
    </div>
  )
})

export default Decoration
